import React, { useState } from "react";
import PropTypes from "prop-types";

import globalStyles from "./styles";
import clsx from "clsx";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import Collapse from "@material-ui/core/Collapse";

const NestedList = ({ hauptelements, pages, path }) => {
    const classes = globalStyles();

    const ListItemLink = ( props ) =>  {
        return <ListItem button component="a" { ...props } />;
    };

    const HauptMenu = ({ hauptelement }) => {
        const [ open, setOpen ] = useState( false );
        const handleClick = () => {
            setOpen( !open );
        };

        let linkSingleElement;
        if( hauptelement === "/" ){
            linkSingleElement = (
                <ListItemLink
                    href="/"
                    className={ clsx(classes.linkList, {
                        [classes.linkStart]: (hauptelement === "/")
                    })}>
                    <ListItemText  primary="Startseite" className={ clsx(classes.linkList, {
                        [classes.colorGreen]: (path === "/")})}/>
                </ListItemLink>
            );
        }else if( hauptelement === "Kostenlos testen"){
            linkSingleElement = (
                <ListItemLink
                    href="/kostenlos-testen"
                    className={ clsx(classes.linkList, {
                        [classes.linkStart]: (hauptelement === "Kostenlos testen")
                    })}>
                    <ListItemText  primary="Kostenlos testen" className={ clsx(classes.linkList, {
                        [classes.colorGreen]: (path === "/kostenlos-testen")})}/>
                </ListItemLink>
            );
        }else if( hauptelement === "Kontakt"){
            linkSingleElement = (
                <ListItemLink
                    href="/kontakt"
                    className={ clsx(classes.linkList, {
                        [classes.linkStart]: (hauptelement === "Kontakt")
                    })}>
                    <ListItemText  primary="Kontakt" className={ clsx(classes.linkList, {
                        [classes.colorGreen]: (path === "/kontakt")})}/>
                </ListItemLink>
            );
        }else if( hauptelement === "Datenschutz" ){
            linkSingleElement = (
                <ListItemLink
                    href="/datenschutz"
                    className={ clsx(classes.linkList, {
                        [classes.linkStart]: (hauptelement === "Datenschutz")
                    })}>
                    <ListItemText  primary="Datenschutz" className={ clsx(classes.linkList, {
                        [classes.colorGreen]: (path === "/datenschutz")})}/>
                </ListItemLink>
            );
        }else if( hauptelement === "Nutzungsbedingungen" ){
            linkSingleElement = (
                <ListItemLink
                    href="/nutzungsbedingungen"
                    className={ clsx(classes.linkList, {
                        [classes.linkStart]: (hauptelement === "Nutzungsbedingungen")
                    })}>
                    <ListItemText  primary="Nutzungsbedingungen" className={ clsx(classes.linkList, {
                        [classes.colorGreen]: (path === "/nutzungsbedingungen")})}/>
                </ListItemLink>
            );
        }else if( hauptelement === "Unterstützung von Abläufen" ){
            linkSingleElement = (
                <ListItemLink
                    href="/info/unterstuetzung_von_ablaufen"
                    className={ clsx(classes.linkList, {
                        [classes.linkStart]: (hauptelement === "Unterstützung von Abläufen")
                    })}>
                    <ListItemText  primary="Unterstützung von Abläufen" className={ clsx(classes.linkList, {
                        [classes.colorGreen]: (path === "/info/unterstuetzung_von_ablaufen")})}/>
                </ListItemLink>
            );
        }else{
            linkSingleElement = <ListItemText  primary={ hauptelement } className={ classes.linkList}/>;
        }

        let singleElement;
        if(hauptelement === "/" ||
        hauptelement === "Datenschutz" ||
        hauptelement === "Nutzungsbedingungen" ||
        hauptelement === "Unterstützung von Abläufen" ||
        hauptelement === "Kostenlos testen" ||
        hauptelement === "Kontakt") {
            singleElement = "";
        }else{
            singleElement = ( open ?
                <ExpandLess className={ classes.colorGreenIcon }/>
                :
                <ExpandMore className={ classes.colorGreenIcon }/>
            );
        }

        let content;
        if (singleElement) {
            content = (
                <ListItem
                    button
                    onClick={ handleClick }
                    className={ classes.listItem }>
                    { linkSingleElement }
                    { singleElement }
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                pages.map(( o, ind ) => {
                                    if(o.node.frontmatter.hauptelement === hauptelement &&
                                        o.node.frontmatter.hauptelement != "Datenschutz" &&
                                        o.node.frontmatter.hauptelement != "Nutzungsbedingungen" &&
                                        o.node.frontmatter.hauptelement != "Unterstützung von Abläufen"){
                                        return <ListItem tabindex="-1" key={ ind } button
                                            className={ classes.listItem }>
                                            <ListItemLink
                                                href={ o.node.frontmatter.path }
                                                className={ classes.linkList }>
                                                <ListItemText  primary={ o.node.frontmatter.title }
                                                    className={ clsx({ [classes.colorGreen]: (path === o.node.frontmatter.path)}) }/>
                                            </ListItemLink>
                                        </ListItem>;
                                    }})
                            }
                        </List>
                    </Collapse>
                </ListItem>);
        } else {
            content = (
                <ListItem
                    button
                    tabindex="-1"
                    onClick={ handleClick }
                    className={ classes.listItem }>
                    { linkSingleElement }
                    { singleElement }
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {
                                pages.map(( o, ind ) => {
                                    if(o.node.frontmatter.hauptelement === hauptelement &&
                                        o.node.frontmatter.hauptelement != "Datenschutz" &&
                                        o.node.frontmatter.hauptelement != "Nutzungsbedingungen" &&
                                        o.node.frontmatter.hauptelement != "Unterstützung von Abläufen"){
                                        return <ListItem key={ ind } button
                                            className={ classes.listItem }>
                                            <ListItemLink
                                                href={ o.node.frontmatter.path }
                                                className={ classes.linkList }>
                                                <ListItemText  primary={ o.node.frontmatter.title }
                                                    className={ clsx({ [classes.colorGreen]: (path === o.node.frontmatter.path)}) }/>
                                            </ListItemLink>
                                        </ListItem>;
                                    }})
                            }
                        </List>
                    </Collapse>
                </ListItem>);
        }

        return <> {content} </>;
    };

    const content = (
        hauptelements.map(( o, ind ) => {
            return (<HauptMenu
                key={ ind }
                hauptelement={ o }/>);
        })
    );

    return <> {content} </>;
};

NestedList.propTypes = {
    hauptelements: PropTypes.array.isRequired,
    pages: PropTypes.array.isRequired,
    path: PropTypes.string.isRequired,
};

export default NestedList;
