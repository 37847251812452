import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import PropTypes from "prop-types";

import globalStyles from "./styles";

import Button from "@material-ui/core/Button";
import MenuRoundedIcon from "@material-ui/icons/MenuRounded";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";

import List from "@material-ui/core/List";

import NestedList from "./NestedList";

const Navigation = ({ path }) => {
    const classes = globalStyles();
    const styles = {
        button: {
            position: "fixed",
            top: "0.7em",
            right: "1em",
            color: "#3f3f41",
            backgroundColor: "hsla(0,0%,100%,.7)",
            boxShadow: "0 0 10px rgba(0,0,0,0.3)"
        },
        buttonWhite: {
            color: "white",
            borderColor: "white"
        },
        appBar: {
            background: "rgba(20.7, 29.4, 38.2, 0.0)",
            boxShadow: "none"
        },
        appBarColor: {
            background: "rgba(20.7, 29.4, 38.2, 0.7)",
        },

    };

    const data = useStaticQuery(
        graphql`
            query {
                allFile(filter: {name: {eq: "logo_hell"}}) {
                    edges {
                        node {
                            publicURL
                        }
                    }
                }
                allMarkdownRemark(sort: {fields: fileAbsolutePath}) {
                    edges {
                      node {
                        frontmatter {
                          title
                          path
                          hauptelement
                        }
                        id
                     }
                  }
                }
            }
        `
    );
    
    const hauptelementList = ["/"];
    data.allMarkdownRemark.edges.map((o) => {
        let i;
        for(i = 0; i < hauptelementList.length; i++){
            if(hauptelementList[i] === o.node.frontmatter.hauptelement)break;
        }
        if(i == hauptelementList.length){
            hauptelementList.push(o.node.frontmatter.hauptelement);
        }
    });
    const index = hauptelementList.findIndex((x) => x === "Datenschutz");
    hauptelementList.splice(index, 0, "Produkte");
    hauptelementList.splice(index + 1, 0, "Kostenlos testen");
    hauptelementList.push("Kontakt");

    const pageList = data.allMarkdownRemark.edges;
    const klassenbuch = {
        node: {
            frontmatter: {
                title: "Elektronische Unterrichtsdokumentation",
                path: "/info/produkte/klassenbuch",
                hauptelement: "Produkte",
            }
        }
    };
    const fehlzeitenerfassung = {
        node: {
            frontmatter: {
                title: "Fehlzeitenerfassung",
                path: "/info/produkte/fehlzeitenerfassung",
                hauptelement: "Produkte",
            }
        }
    };
    const newList = pageList.concat(klassenbuch).concat(fehlzeitenerfassung);

    const logo =
         data.allFile.edges.map((o, ind) => {
             return (
                 <img key={ind}  src={o.node.publicURL}
                     alt="logo"
                     className={ classes.logoImMenu }/>
             );});



    const [state, setState] = useState({
        right: false,
    });
    const toggleDrawer = (anchor, open) => (event) => {
        if (event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setState({ ...state, [anchor]: open });
    };
    const list = (anchor) => (
        <div className={ classes.listRoot }>
            <div onClick={toggleDrawer(anchor, false)}
                className={ classes.logoBoxImMenu }>
                { logo }
            </div>
            <div>
                <List className="menuNav" component="nav">
                    <NestedList
                        path={ path }
                        pages={ newList }
                        hauptelements={ hauptelementList } />
                </List>
            </div>
        </div>
    );

    const content = (

        <div>
            {["right"].map((anchor) => (
                <React.Fragment key={anchor}>
                    <AppBar style={{ ...styles.appBar }}>
                        <Toolbar>
                            <Button style={{...styles.button}}
                                onClick={toggleDrawer(anchor, true)}>
                                MENU
                                <MenuRoundedIcon />
                            </Button>
                            <Drawer className="menuNav"
                                anchor={anchor}
                                open={state[anchor]}
                                onClose={toggleDrawer(anchor, false)}>
                                {list(anchor)}
                            </Drawer>
                        </Toolbar>
                    </AppBar>
                </React.Fragment>
            ))}
        </div>
    );
    return <> {content} </>;
};

Navigation.propTypes = {
    path: PropTypes.string.isRequired,
};

export default Navigation;
